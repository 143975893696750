// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-blog-helped-jsx": () => import("./../../../src/templates/blog-helped.jsx" /* webpackChunkName: "component---src-templates-blog-helped-jsx" */),
  "component---src-templates-blog-highlight-jsx": () => import("./../../../src/templates/blog-highlight.jsx" /* webpackChunkName: "component---src-templates-blog-highlight-jsx" */),
  "component---src-templates-blog-individual-jsx": () => import("./../../../src/templates/blog-individual.jsx" /* webpackChunkName: "component---src-templates-blog-individual-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

