console.log("Project Nightfall Philanthropy");

document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
        initializeScrollUpDownListener();
    }
}

function initializeScrollUpDownListener() {
     let lastScrollTop = 0;

     // this is only for mobile/smaller screens, max width 992
     document.onscroll = () => {
         const headerNavigation = document.getElementById('headerNavigation');
         const burgerBtn = document.getElementById('burgerButton');
         const mobileMenuBg = document.getElementById('mobileMenuBg');
         const screenWidth = window.screen.width || document.documentElement.screen.width;
         if (screenWidth >= 992) return;

        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop){
            console.log('down');
            headerNavigation.style.marginTop = '-15px';
            headerNavigation.style.opacity = '0';
            headerNavigation.classList.remove('open-menu');
            burgerBtn.style.backgroundImage = 'url(https://cdn.builder.io/api/v1/image/assets%2Ff32c757c94b24306a04c53a49133c5f4%2Fe071a26821a24882a38a184e50ff3afd)';
            setTimeout(() => {
                mobileMenuBg.style.opacity = 0;
            }, 700);
        } else {
            console.log('up');
            headerNavigation.style.marginTop = '0';
            headerNavigation.style.opacity = '1';
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    };
}
